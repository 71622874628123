/* css full witdth that item inside fill up space fill  */
.home-buttons-nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1200px;
    align-items: center;
    height: 40px;
    /* @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */
    padding: 2px 5px 2px 5px ;
}
.home-buttons-nav .home-btn-nav{
    height: 100%;
    text-align: center;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* @media (max-width: 768px) {
        width: 100%;
        margin: 10px 0;
    } */
    background-color: #f5f5f5;
    cursor: pointer;
}
/* home-btn-nav hover colour */
.home-buttons-nav .home-btn-nav:hover{
    background-color: #f5f5f5;
    /* border-radius: 10px; */
 
}
/* home-btn-nav active colour */
.home-buttons-nav .home-btn-nav.active{
    background-color: white;
    color: rgb(49, 60, 70);
    /* round conner top */
        border-radius: 10px 0 10px 0;
}


/* class for div that will center content inside */
.center-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: bottom;
    height: 100%;
}


/* temp  */

.w-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .w-background-image {
    width: 100%;
    height: 100%;
  }
  
  .w-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  

  .watermark {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 32px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.22); /* Adjust the opacity as needed */
    transform: rotate(45deg);
    pointer-events: none; /* Allows clicking through the watermark */
    z-index: 1;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 30px, rgba(255, 255, 255, 0.5) 30px, rgba(255, 255, 255, 0.5) 60px);
  
  }
  